.w-100{
  border-radius: 20px;
}
.carousel-indicators li {
  background-color: gray;
  border-radius: 100px;
  height: 10px;
  width: 10px;
  border-radius: 50px;
  margin:0px 20px
}
.carousel-indicators .active {
  background-color: red;
  border-radius: 100px;
  height: 10px;
  width: 10px;
  border-radius: 50px;
}
.carousel-indicators {
  bottom:-50px;
}
li{
    z-index: 1;
}

.flip-card {
  background-color: transparent;
    width: 80%;
    margin-left: 2%;
    height: 250px;
    perspective: 1000px;
    border-radius: 18px;
    margin-top: 40px;
}
@media only screen and (max-width: 600px) {
  .flip-card{
    background-color: transparent;
    width: 250px;
    margin-left: 2%;
    height: 250px;
    perspective: 1000px;
    border-radius: 18px;
    margin-top: 80px;
  }
  
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border-radius: 16px;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  /* background-color: #bbb; */
  color: black;
}

.flip-card-back {
  /* background-color: #2980b9; */
  color: white;
  transform: rotateY(180deg);
  border-radius: 16px;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-size: auto 250px;
}
.flip-card-back1 {
  /* background-color: #2980b9; */
  background-image: url("./public/assets//images/back1.png");
}
.flip-card-back2 {
  /* background-color: #2980b9; */
  background-image: url("./public/assets//images/back2.png");
}
.flip-card-back3 {
  /* background-color: #2980b9; */
  background-image: url("./public/assets//images/back3.png");
}
.flip-card-back4 {
  /* background-color: #2980b9; */
  background-image: url("./public/assets//images/back4.png");
}
.flip-card-back5 {
  /* background-color: #2980b9; */
  background-image: url("./public/assets/serviceIamgesUpdated/endo22.png");
}
.flip-card-back6 {
  /* background-color: #2980b9; */
  background-image: url("./public/assets//images/back6.png");
}
.card-text{
  position: 'absolute';
  text-align: center;
  top:10%;
  color: black;
  margin-top: 50px;
  padding: 20px;
  font-family: Poppins;
  font-weight: 700;
}
.service-header-smile{
  background-image: url("./public/assets/serviceImages/servicesmile.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.react-multi-carousel-list{
  padding: 20px;
}
.carousel{
  z-index: 0;
}

.before-image-container {
  position: relative;
  width: 100%;
}

.before-image {
  width: 100%;
  height: auto;
  border-radius: 20px;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 100%;
  right: 0;
  background-color: #000;
  overflow: hidden;
  width: 0;
  height: 100%;
  transition: .5s ease;
}

.before-image-container:hover .overlay {
  width: 100%;
  left: 0;
}
.after-image {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 40px;
}

.grid2 {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}

@media only screen and (max-width: 600px) {
  .navcontainermedia{
    margin-top: 50px;
  }
}

.navlist2{
  color:#fff
}

@media only screen and (max-width: 600px) {
  .navlist2{
    color:#fff;
    margin-top:10;
  }
  
}

.service-card-container {
  position: relative;
  width: 100%;
  height: 300px;
  margin-top: 50px;
}

.service-card-image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
  width:100%;
  border-radius: 20px;
  height: 300px;
  object-fit: cover;
 
}

.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 30%;
  left: 50%;
  width: 80%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.service-card-container:hover .service-card-image {
  opacity: 0.5;
}

.service-card-container:hover .middle {
  opacity: 1;
}

.service-card-text {
  color: #000;
  font-size: 16px;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;

}

.example {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}