@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
* {
  scroll-margin-top: 100px;
}


/* Hide scrollbar for IE, Edge and Firefox */
/* *::-webkit-scrollbar {
  display: none;
}

* {
  -ms-overflow-style: none; 
  scrollbar-width: none;
} */

.w-100{
  border-radius: 20px;
}
.carousel-indicators li {
  background-color: gray;
  border-radius: 100px;
  height: 10px;
  width: 10px;
  border-radius: 50px;
  margin:0px 20px
}
.carousel-indicators .active {
  background-color: red;
  border-radius: 100px;
  height: 10px;
  width: 10px;
  border-radius: 50px;
}
.carousel-indicators {
  bottom:-50px;
}
li{
    z-index: 1;
}

.flip-card {
  background-color: transparent;
    width: 80%;
    margin-left: 2%;
    height: 250px;
    perspective: 1000px;
    border-radius: 18px;
    margin-top: 40px;
}
@media only screen and (max-width: 600px) {
  .flip-card{
    background-color: transparent;
    width: 250px;
    margin-left: 2%;
    height: 250px;
    perspective: 1000px;
    border-radius: 18px;
    margin-top: 80px;
  }
  
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border-radius: 16px;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  /* background-color: #bbb; */
  color: black;
}

.flip-card-back {
  /* background-color: #2980b9; */
  color: white;
  transform: rotateY(180deg);
  border-radius: 16px;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-size: auto 250px;
}
.flip-card-back1 {
  /* background-color: #2980b9; */
  background-image: url(/static/media/back1.7211fbb4.png);
}
.flip-card-back2 {
  /* background-color: #2980b9; */
  background-image: url(/static/media/back2.a34e076c.png);
}
.flip-card-back3 {
  /* background-color: #2980b9; */
  background-image: url(/static/media/back3.8ce09245.png);
}
.flip-card-back4 {
  /* background-color: #2980b9; */
  background-image: url(/static/media/back4.6c7917a4.png);
}
.flip-card-back5 {
  /* background-color: #2980b9; */
  background-image: url(/static/media/endo22.3bb58b21.png);
}
.flip-card-back6 {
  /* background-color: #2980b9; */
  background-image: url(/static/media/back6.b9b33ce9.png);
}
.card-text{
  position: 'absolute';
  text-align: center;
  top:10%;
  color: black;
  margin-top: 50px;
  padding: 20px;
  font-family: Poppins;
  font-weight: 700;
}
.service-header-smile{
  background-image: url(/static/media/servicesmile.4698a886.png);
  background-repeat: no-repeat;
  background-size: contain;
}
.react-multi-carousel-list{
  padding: 20px;
}
.carousel{
  z-index: 0;
}

.before-image-container {
  position: relative;
  width: 100%;
}

.before-image {
  width: 100%;
  height: auto;
  border-radius: 20px;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 100%;
  right: 0;
  background-color: #000;
  overflow: hidden;
  width: 0;
  height: 100%;
  transition: .5s ease;
}

.before-image-container:hover .overlay {
  width: 100%;
  left: 0;
}
.after-image {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 40px;
}

.grid2 {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}

@media only screen and (max-width: 600px) {
  .navcontainermedia{
    margin-top: 50px;
  }
}

.navlist2{
  color:#fff
}

@media only screen and (max-width: 600px) {
  .navlist2{
    color:#fff;
    margin-top:10;
  }
  
}

.service-card-container {
  position: relative;
  width: 100%;
  height: 300px;
  margin-top: 50px;
}

.service-card-image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  width:100%;
  border-radius: 20px;
  height: 300px;
  object-fit: cover;
 
}

.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 30%;
  left: 50%;
  width: 80%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.service-card-container:hover .service-card-image {
  opacity: 0.5;
}

.service-card-container:hover .middle {
  opacity: 1;
}

.service-card-text {
  color: #000;
  font-size: 16px;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;

}

.example {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bot {
  bottom: 0;
  right : 0;
  position: fixed;
  width: 350px;
  z-index: 4;
}
*{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.Navbarlogo{
    background-color: rgb(255 252 253);
    position: fixed;
    width: 100vw;
    display: grid;
    z-index: 4;
}
.imgbar {
    width: 23vw;
    padding: 9px;
}
.callbtn{
    width: 8vw;
    float: right;
    background-color: rgb(222, 28, 73);
    border-radius: 15px;
    padding: 9px;
    font-weight: 700;
    margin: 25px;
}
.call{
    color: #fff;
}
.number{
    font-size: 19px;
    background-color: rgb(222, 28, 73);
    color: #fff;
    padding: 8px 4px;
}
@media screen and (max-width: 570px) {
    .imgbar {
        width: 57vw;
    }
    .callbtn{
        width: 23vw;
        margin: 25px;
    }
}
@media screen and (max-width: 870px) {
    .imgbar {
        width: 57vw;
    }
    .callbtn{
        width: 23vw;
        margin: 25px;
    }
}

