.Navbarlogo{
    background-color: rgb(255 252 253);
    position: fixed;
    width: 100vw;
    display: grid;
    z-index: 4;
}
.imgbar {
    width: 23vw;
    padding: 9px;
}
.callbtn{
    width: 8vw;
    float: right;
    background-color: rgb(222, 28, 73);
    border-radius: 15px;
    padding: 9px;
    font-weight: 700;
    margin: 25px;
}
.call{
    color: #fff;
}
.number{
    font-size: 19px;
    background-color: rgb(222, 28, 73);
    color: #fff;
    padding: 8px 4px;
}
@media screen and (max-width: 570px) {
    .imgbar {
        width: 57vw;
    }
    .callbtn{
        width: 23vw;
        margin: 25px;
    }
}
@media screen and (max-width: 870px) {
    .imgbar {
        width: 57vw;
    }
    .callbtn{
        width: 23vw;
        margin: 25px;
    }
}
